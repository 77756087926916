.rbc-time-view {
  border: 0.5px solid #99999b;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.rbc-time-view .rbc-allday-cell {
  display: none;
}


.rbc-time-header-content,
.rbc-time-content > * + * > * {
  border-left: 0.5px solid #99999b;
}

.rbc-time-header.rbc-overflowing {
  border-right: none;
}

.rbc-time-content {
  border-top: none;
}

.rbc-timeslot-group {
  border-bottom: 0.5px solid #99999b;
}
.rbc-label {
  padding: 0 9.5px;
  font-size: 14px;
}

.rbc-current-time-indicator {
  height: 2px;
  background-color: #30bfad;
}
.rbc-day-slot .rbc-events-container .rbc-event {
  border: 1px solid #fefefe;
  background-color: #4d54c9;
  color: #fefefe;
}

