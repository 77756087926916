.rbc-event {
  font-size: 12px;
  color: #99999b;
  margin: 0;
  padding: 4px 8px;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #e7e8ff;
}

.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: 2px auto #4d54c9;
}

.rbc-event-time {
  color: #40404b;
}
