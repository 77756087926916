.rbc-agenda-view table.rbc-agenda-table {
  border: 0.5px solid #99999b;
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  border-bottom: none;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  color: #99999b;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td,
.rbc-header + .rbc-header,
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  border-left: 0.5px solid #99999b;
}

.rbc-agenda-date-cell {
  vertical-align: middle;
}

.rbc-overlay {
  border-radius: 16px;
  max-width: 360px;
  max-height: 360px;
  overflow-y: auto;
}

.rbc-overlay-header {
  padding: 8px 10px;
}
