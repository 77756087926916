.rbc-month-view {
  border: 0.5px solid #99999b;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.rbc-header {
  padding: 8px;
  font-size: 90%;
  min-height: 0;
  color: #40404b;
  font-weight: 500;
  border-bottom: 0.5px solid #99999b;
}

.rbc-off-range-bg {
  background: #f8f8ff;
}
.rbc-date-cell.rbc-off-range {
  color: #e7e8ff;
}
.rbc-date-cell {
  text-align: left;
  padding-left: 8px;
  color: #4d54c9;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 0.5px solid #99999b;
}

.rbc-button-link {
  color: inherit;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
}

.rbc-button-link.rbc-show-more {
  color: #30BFAD;
  padding-left: 8px;
}

.rbc-today {
  background-color: #fefefe;
}

.rbc-day-bg + .rbc-day-bg + .rbc-today {
  border-left: none;
  border-right: none;
  border: 1px solid #4d54c9;
}
